const API_URL = process.env.VUE_APP_API_URL
export default {
  async getPostulantes (page, filters) {
    try {
      console.log(page)
      const response = await fetch(`${API_URL}getApplicants&page=${page}&filter=${filters && filters.vacante ? filters.vacante : ''}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'token'
        }
      })
      const resp = await response.json()
      if (!response.ok) {
        if (response.status === 400) {
          return Promise.reject(new Error(resp.error))
        }
      }
      return resp
    } catch (error) {
      return { success: false }
    }
  },
  async getPostulante (applicantId) {
    try {
      const response = await fetch(`${API_URL}getApplicantDetail&applicantId=${applicantId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'token'
        }
      })
      const resp = await response.json()
      if (!response.ok) {
        if (response.status === 400) {
          return Promise.reject(new Error(resp.error))
        }
      }
      return resp
    } catch (error) {
      return { success: false }
    }
  },
  async updateStatusPostulante (applicantId, status) {
    try {
      const response = await fetch(`${API_URL}updateApplicantStatus&applicantId=${applicantId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'token'
        },
        body: JSON.stringify({ status })
      })
      const resp = await response.json()
      if (!response.ok) {
        if (response.status === 400) {
          return Promise.reject(new Error(resp.error))
        }
      }
      return resp
    } catch (error) {
      return { success: false }
    }
  }
}
