const API_URL = process.env.VUE_APP_API_URL

export default {
  async getVacantes (page, filters) {
    try {
      console.log('Obtener lista de vacantes', page, filters)
      const response = await fetch(`${API_URL}getVacants&page=${page}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'token'
        }
      })
      const resp = await response.json()
      if (!response.ok) {
        if (response.status === 400) {
          return Promise.reject(new Error(resp.error))
        }
      }
      return resp
    } catch (error) {
      return { success: false }
    }
  },
  async getVacanteById (vacanteId) {
    try {
      const response = await fetch(`${API_URL}getVacantById&vacantId=${vacanteId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'token'
        }
      })
      const resp = await response.json()
      if (!response.ok) {
        if (response.status === 400) {
          return Promise.reject(new Error(resp.error))
        }
      }
      return resp
    } catch (error) {
      return { success: false }
    }
  },
  async eliminarVacante (vacanteId) {
    try {
      const response = await fetch(`${API_URL}deleteVacant&vacantId=${vacanteId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'token'
        }
      })
      const resp = await response.json()
      if (!response.ok) {
        if (response.status === 400) {
          return Promise.reject(new Error(resp.error))
        }
      }
      return resp
    } catch (error) {
      return { success: false }
    }
  },

  async actualizarVacante (vacanteId, infoVacante) {
    try {
      const response = await fetch(`${API_URL}editVacant&vacantId=${vacanteId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'token'
        },
        body: JSON.stringify(infoVacante)
      })
      const resp = await response.json()
      if (!response.ok) {
        if (response.status === 400) {
          return Promise.reject(new Error(resp.error))
        }
      }
      return resp
    } catch (error) {
      return { success: false }
    }
  },
  async crearVacante (infoVacante) {
    try {
      const response = await fetch(`${API_URL}saveVacant`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'token'
        },
        body: JSON.stringify(infoVacante)
      })
      const resp = await response.json()
      if (!response.ok) {
        if (response.status === 400) {
          return Promise.reject(new Error(resp.error))
        }
      }
      return resp
    } catch (error) {
      return { success: false }
    }
  }
}
