const API_URL = process.env.VUE_APP_API_URL

export default {
  async getUsuarios (page) {
    try {
      const response = await fetch(`${API_URL}getUserAdmin&page=${page}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'token'
        }
      })
      const resp = await response.json()
      if (!response.ok) {
        if (response.status === 400) {
          return Promise.reject(new Error(resp.error))
        }
      }
      return resp
    } catch (error) {
      return { success: false }
    }
  },
  async getUsuario (userId) {
    try {
      const response = await fetch(`${API_URL}getUserAdminById&userId=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'token'
        }
      })
      const resp = await response.json()
      if (!response.ok) {
        if (response.status === 400) {
          return Promise.reject(new Error(resp.error))
        }
      }
      return resp
    } catch (error) {
      return { success: false }
    }
  },
  async eliminarUsuario (vacanteId) {
    try {
      const response = await fetch(`${API_URL}deleteVacant&vacantId=${vacanteId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'token'
        }
      })
      const resp = await response.json()
      if (!response.ok) {
        if (response.status === 400) {
          return Promise.reject(new Error(resp.error))
        }
      }
      return resp
    } catch (error) {
      return { success: false }
    }
  },

  async updateUsuario (userId, infoVacante) {
    try {
      const response = await fetch(`${API_URL}editUserAdmin&userId=${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'token'
        },
        body: JSON.stringify(infoVacante)
      })
      const resp = await response.json()
      if (!response.ok) {
        if (response.status === 400) {
          return Promise.reject(new Error(resp.error))
        }
      }
      return resp
    } catch (error) {
      return { success: false }
    }
  },
  async saveUsuario (infoVacante) {
    try {
      const response = await fetch(`${API_URL}createUserAdmin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'token'
        },
        body: JSON.stringify(infoVacante)
      })
      const resp = await response.json()
      if (!response.ok) {
        if (response.status === 400) {
          return Promise.reject(new Error(resp.error))
        }
      }
      return resp
    } catch (error) {
      return { success: false, message: error.message }
    }
  },

  async deleteUsuario (userId) {
    try {
      const response = await fetch(`${API_URL}deleteUserAdmin&userId=${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'token'
        }
      })
      const resp = await response.json()
      if (!response.ok) {
        if (response.status === 400) {
          return Promise.reject(new Error(resp.error))
        }
      }
      return resp
    } catch (error) {
      return { success: false, message: error.message }
    }
  },

  /// Login
  async login (infoUser) {
    try {
      const response = await fetch(`${API_URL}login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'token'
        },
        body: JSON.stringify(infoUser)
      })
      const resp = await response.json()
      if (!response.ok) {
        if (response.status === 400) {
          return Promise.reject(new Error(resp.error))
        }
      }
      return resp
    } catch (error) {
      return { success: false, message: error.message }
    }
  }
}
